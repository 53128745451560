body {
    background-color: #fff;
    color: #000;
    font-family: 'Barlow Semi Condensed', sans-serif !important;
}

.page-container {
    max-width: 900px;
    min-height: 100vh;
    position: relative;
    padding-bottom: 400px;
}

.footer {
    background-color: #3a3934;
    color: white;
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
    max-height: 900px;
}

.footer-element {
    border-right: 1px solid #7f7d75;
    margin-right: 10px;
}

.footer-end {
    display: flex;
    flex-direction: row;
    list-style: none;
    text-align: start;
    border-top: 1px solid #7f7d75;
    padding-top: 30px;
}

.footer-link {
    list-style: none;
    text-align: start;
    padding: 0px;
}

.footer-link li:nth-child(1) {
    font-weight: bolder;
}

.footer-link a,
.footer-end a {
    color: white;
}

.footer-link a:hover,
.footer-end a:hover,
.footer-language:hover {
    text-decoration: underline;
    cursor: pointer;
}

.email-link {
    color: #fd1722 !important;
}

@media all and (max-width: 770px) {
    .page-container {
        padding-bottom: 1000px;
    }

    .footer-end {
        flex-direction: column;
    }

    .footer-element {
        border-right: none;
        margin-bottom: 20px;
    }
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

@media all and (max-width: 900px) {
    .fill {
        padding: 0px !important;
    }
}

a {
    text-decoration: none;
}

.fill img {
    flex-shrink: 0;
    min-width: 100%;
    max-height: 100%;
}

.flyer {
    width: 100px;
    display: block;
    margin: auto;
}

.button {
    background-color: #068d2d;
    color: white;
    border: none;
    padding: 4px 40px;
    text-decoration: none;
    text-transform: uppercase;
}

.button:hover {
    color: white;
    background-color: #046520;
    border: none;
    padding: 4px 40px;
    text-decoration: none;
    text-transform: uppercase;
}

.button:disabled {
    background-color: #068d2d;
    color: white;
    opacity: 0.6;
    pointer-events: none;
}

.input-field {
    border: none;
    border-bottom: 1px solid #9dbac2;
    border-radius: 0px;
}

.custom-hr {
    border: 1px solid #fd1722;
    margin: 24px 0px;
}

.description {
    color: #fd1722;
    font-size: small;
    cursor: pointer;
}

.spinner {
    height: 3rem;
    width: 3rem;
}

.text-primary {
    color: #fd1722 !important;
}

.text-secondary {
    color: #9dbac2 !important;
}

.underline {
    position: relative;
    display: inline-block;
}

.underline::after {
    content: "";
    position: absolute;
    left: 20%;
    bottom: -8px;
    width: 60%;
    height: 3px;
    background-color: #fd1722;
}

.category {
    padding: 8px 0px;
    border-bottom: 1px solid #9dbac2;
}

.category:hover {
    cursor: pointer;
}

.category-hide {
    color: gray !important;
    pointer-events: none;
}

/* .category div {
    margin: auto;
    justify-content: center;
} */

.promotion-category {
    color: #068d2d;
}

.cursor-pointer {
    cursor: pointer;
}

.agb-url {
    color: #4992fd;
    cursor: pointer;
}

.agb-url:hover {
    color: #0a58ca;
}